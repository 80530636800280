import React from 'react';

import Auxi from '../../hoc/Auxi';
import {Redirect} from 'react-router';
import axios from 'axios';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom';

import '../../assets/scss/custom-tab-styles/cdrs.scss';



import PullToRefresh from 'react-simple-pull-to-refresh';
import { filter } from 'lodash';
// import {PullToRefresh} from "react-js-pull-to-refresh";
// import {PullDownContent, ReleaseContent, RefreshContent} from "react-js-pull-to-refresh";

class CDRS extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            filter: "all",
            type: "User",
            userInfo: {}

        };
    }

    variants = {
        enter: (direction) => {
            return {
                x: direction > 0 ? 1000 : -1000,
                opacity: 0
            };
        },
        center: {
            zIndex: 1,
            x: 0,
            opacity: 1
        },
        exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        };
        }
    };
    direction = -1;

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }

    trackScrolling = () => {
        
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            console.log("At bottom")
        }

    };
    // onFocus = () => {
    //     this.getCdrs(this.state.userInfo.extension, this.state.userInfo.tenant_id, this.state.token);
    // }
    visibilityChange=() => {
        if(document.visibilityState === "visible"){
            this.getCdrs(this.state.type, this.state.userInfo.extension, this.state.userInfo.tenant_id, this.state.token);
        }
    }
    componentDidMount(){
        document.addEventListener('scroll', this.trackScrolling);
        // window.addEventListener("focus", this.onFocus);
        document.addEventListener("visibilitychange", this.visibilityChange);
        // infoOnExistingUser will provide the settings data

        let url = decodeURIComponent(window.location.href)
        url = new URL(url);
        let mUrl = decodeURIComponent(window.location.href)
        var username = mUrl.substring(
            mUrl.lastIndexOf("?username=") + 10,
            mUrl.lastIndexOf("&password="),
        ).toLowerCase();
        var password = url.searchParams.get("password");
        var cloudId = url.searchParams.get("cloud_id");

        this.setState({username: username});
        this.setState({password: password});
        this.setState({cloudId: cloudId});

        const urlParams = "?username="+encodeURIComponent(username)+"&password="+password+ "&cloud_id="+cloudId
        this.setState({urlParams: urlParams});
        
        const payLoad = {
            cloud_username: username,
            cloud_password: password
        };
        axios.post(`${process.env.REACT_APP_API_URL}userInformation`, payLoad)
        .then(response=>{
            const userInfo = {
                'admin':response.data.admin,
                'extension':response.data.extension,
                'firstname': response.data.firstname,
                'lastname': response.data.lastname,
                'pin': response.data.pin,
                'tenant_id': response.data.tenant_id,
            }
            this.setState({loading: false});
            this.setState({userInfo});
            let token = response.data.extension + "@" + response.data.tenant_id + ":" + response.data.pin
                token = btoa(token);
            this.setState({token: token});
            this.getCdrs("User", response.data.extension, response.data.tenant_id, token);
        })
        .catch(err=>{
            this.setState({loading: true});
            if(err.response.data){
                toast.error(err.response.data);
                return
            }
            toast.error(err.message);
            
        });

        
    }

    getCdrs = (type, extension, tenant, token) => {
        const pullElement = document.getElementsByClassName("ptr__pull-down");
        const childElement = document.getElementsByClassName("ptr__children");
        if(pullElement.length > 0){
            pullElement[0].style.visibility = "hidden";
            childElement[0].style.transform = "none";
        }
        
        const payLoad = {
            tenant_id: tenant,
            token: token,
            user: extension
        };
        let url = "getCDRs"
        if(type === "Group"){
            url = "getGroupCDRs"
        }
        axios.post(`${process.env.REACT_APP_API_URL}${url}`, payLoad)
        .then(response=>{
            const cdrRecords = response.data;
            if(response.data === null){
                toast.error("No record found");
                this.populateRecords([]);
                return;
            }
            this.setState({cdrRecords: cdrRecords});
            this.setState({filteredResults: cdrRecords});
            this.setState({searchValue: ""});
            if(this.state.filter === "all"){
                this.populateRecords(cdrRecords);
                this.setState({filteredResults: cdrRecords});
                return;
            }
            const filteredResults = cdrRecords.filter((record) => {
                if(this.state.filter === "Missed"){
                    if(record.direction === this.state.filter || record.direction === "Missed-VM"){
                        return record;
                    } 
                } else if(record.direction === this.state.filter){
                    return record;
                }
            });
            this.populateRecords(filteredResults);

        })
        .catch(err=>{
            this.setState({loading: true});
            this.populateRecords([]);
            if(err?.response?.data){
                toast.error(err.response.data);
                return
            }
            toast.error(err.message);
            
        });
    }


    populateRecords = (records) => {
        let lastCalendar = "";
        const recordsUi = records?.map((record, index) => {
            // Create initials for the user's name
            const splitedName = record.name.split(" ");
            let nameInitials = "";
            if (splitedName[0]) {
                nameInitials += splitedName[0][0];
            }
            if (splitedName[1]) {
                nameInitials += splitedName[1][0];
            }
    
            // Icon for the record
            let iconDiv = record.name === "" ? (
                <div className="call-user-icon">
                    <span>
                        <FontAwesomeIcon icon={faUser} />
                    </span>
                </div>
            ) : (
                <div className="call-user-initials">
                    <span>
                        <label>{nameInitials}</label>
                    </span>
                </div>
            );
    
            // Timestamp logic
            let utcDate = moment.utc(record.time_stamp, "YYYY-MM-DD HH:mm:ss A").toDate();
            let timeStamp = moment(utcDate, "YYYY-MM-DD HH:mm:ss A").local();
    
            const datePart = timeStamp.format("YYYY-MM-DD");
            const timePart = timeStamp.format("h:mm:ss A");
            let newCalendar = timeStamp.calendar().split(" ")[0];
            if (newCalendar === "Last") {
                newCalendar = timeStamp.calendar().split(" ")[1];
            }
    
            // Calendar-based division (Today, Yesterday, etc.)
            let calendarValue = null;
            if (newCalendar !== lastCalendar) {
                calendarValue = (
                    <div className="col-md-12 date-container">
                        <label>{newCalendar}</label>
                    </div>
                );
            }
            lastCalendar = newCalendar;
    
            // Direction-based icons (Restoring previous icons)
            let direction = null;
            if (record.direction === "Inbound") {
                direction = (
                    <div className="call-status inbound-hover">
                        <div className="call-status-empty"></div>
                        <div className="call-status-top">
                            <i className="fas fa-arrow-down inbound-call" />
                        </div>
                        <div className="call-status-down" title="Call">
                            <i className="fas fa-phone-alt inbound-call" />
                        </div>
                    </div>
                );
            } else if (record.direction === "Outbound") {
                direction = (
                    <div className="call-status outbound-hover">
                        <div className="call-status-empty"></div>
                        <div className="call-status-top">
                            <i className="fas fa-arrow-up outbound-call" />
                        </div>
                        <div className="call-status-down" title="Call">
                            <i className="fas fa-phone-alt outbound-call" />
                        </div>
                    </div>
                );
            } else if (record.direction === "Missed") {
                direction = (
                    <div className="call-status missed-hover">
                        <div className="call-status-empty"></div>
                        <div className="call-status-top">
                            <i className="fas fa-times missed-call" />
                        </div>
                        <div className="call-status-down" title="Call">
                            <i className="fas fa-phone-alt missed-call" />
                        </div>
                    </div>
                );
            } else if (record.direction === "Missed-VM") {
                direction = (
                    <div className="call-status missed-hover">
                        <div className="call-status-empty"></div>
                        <div className="call-status-top">
                            <i className="fas fa-envelope missed-call" />
                        </div>
                        <div className="call-status-down" title="Call">
                            <i className="fas fa-phone-alt missed-call" />
                        </div>
                    </div>
                );
            }
            
            return (
                <Auxi key={index}>
                    {calendarValue}
                    <div className="call-logs-container"
                        onClick={(e) => this.toggleCallFlows(e, record)}
                    >
                        {iconDiv}
                        <div
                            className="call-name"
                            
                        >
                            {record.name || "#"}
                        </div>
                        <div
                            className="call-number"
                        >
                            {record.destination}
                        </div>
                        <div
                            className="call-date-duration"
                        >
                            {datePart} &nbsp; {timePart}
                        </div>
                        {direction}
                    </div>
                </Auxi>
            );
        });
        this.setState({ recordsUi });
    };

    // populateRecords = (records) => {
    //     let lastCalendar = "";
    //     const recordsUi = records.map((record, index)=> {

    //         // first for icon
    //         let iconDiv = null;
    //         const splitedName = record.name.split(" ");
    //         let nameInitials = ""
    //         if(splitedName[0]){
    //             nameInitials += splitedName[0][0]
    //         }
    //         if(splitedName[1]){
    //             nameInitials += splitedName[1][0]
    //         }

    //         if(record.name === ""){
    //             iconDiv = <div className="call-user-icon">
    //                         <span> 
    //                             <FontAwesomeIcon icon={faUser} />
    //                         </span>
    //                     </div>
    //         } else {
    //             iconDiv = <div className="call-user-initials">
    //                         <span> 
    //                             <label>{nameInitials}</label>
    //                         </span>
    //                     </div>
    //         }

    //         // timestamp logic
    //         let utcDate = moment.utc(record.time_stamp, "YYYY-MM-DD HH:mm:ss A").toDate();
    //         let timeStamp = moment(utcDate, "YYYY-MM-DD HH:mm:ss A").local();

    //         const datePart = timeStamp.format('YYYY-MM-DD');
    //         const timePart = timeStamp.format('h:mm:ss A');
    //         let newCalendar = timeStamp.calendar().split(" ")[0];
    //         if(newCalendar === "Last"){
    //             newCalendar = timeStamp.calendar().split(" ")[1];
    //         }

    //         //duration logic
    //         let duration = ""
    //         if (record.duration !== ""){
    //             const durationSplit = record.duration.split(":")
            
    //             if(durationSplit[1] !== "00"){
    //                 duration+= durationSplit[1] + "h "
    //             }
    //             if(durationSplit[2] !== "00" || duration !== ""){
    //                 duration+= durationSplit[2] + "m "
    //             }
    //             if(durationSplit[3] !== "00" || duration !== ""){
    //                 duration+= durationSplit[3] + "s"
    //             }
    //         }
            
    //         // direction logic
    //         let direction = null;
    //         if(record.direction === "Inbound"){
    //             direction = <div className="call-status inbound-hover"> 
    //                     <div className="call-status-empty">
    //                     </div>
    //                     <div className="call-status-top">
    //                         <i className="fas fa-arrow-down inbound-call"/>
    //                     </div>
    //                     <div className="call-status-down" title="Call">
    //                         <a href={"sip:"+record.destination+"?dialAction=autoCall"}>
    //                             <i className="fas fa-phone-alt inbound-call"/>
    //                         </a>
    //                     </div>
    //                 </div>
    //         } else if(record.direction === "Outbound") {

    //             direction =  <div className="call-status outbound-hover"> 
    //                     <div className="call-status-empty">
    //                     </div>
    //                     <div className="call-status-top">
    //                         <i className="fas fa-arrow-up outbound-call"/>
    //                     </div>
    //                     <div className="call-status-down" title="Call">
    //                         <a href={"sip:"+record.destination+"?dialAction=autoCall"}>
    //                             <i className="fas fa-phone-alt outbound-call"/>
    //                         </a>
    //                     </div>
    //                 </div>

    //         } else if(record.direction === "Missed") {
    //             direction = <div className="call-status missed-hover"> 
    //                 <div className="call-status-empty">
    //                 </div>
    //                 <div className="call-status-top">
    //                     <i className="fas fa-times missed-call"/>
    //                 </div>
                    
    //                 <div className="call-status-down" title="Call">
    //                     <a href={"sip:"+record.destination+"?dialAction=autoCall"}>
    //                         <i className="fas fa-phone-alt missed-call"/>
    //                     </a>
    //                 </div>
    //             </div>
    //         } else if(record.direction === "Missed-VM") {
    //             direction = <div className="call-status missed-hover"> 
    //                 <div className="call-status-empty">
    //                 </div>
    //                 <div className="call-status-top">
    //                     <i className="fas fa-envelope missed-call"/>
    //                 </div>
                    
    //                 <div className="call-status-down" title="Call">
    //                     <a href={"sip:"+record.destination+"?dialAction=autoCall"}>
    //                         <i className="fas fa-phone-alt missed-call"/>
    //                     </a>
    //                 </div>
    //             </div>
    //         }

    //         // calendar based divison Today, yesterday, date, etc
    //         let calendarValue = null;
    //         if (newCalendar !== lastCalendar){
    //             calendarValue = <div className="col-md-12 date-container">
    //                         <label>{newCalendar}</label>
    //                     </div>
    //         }
    //         lastCalendar = newCalendar;

    //         let tableData = null;
    //         if(record.ans_call_flow){
    //             tableData = record.ans_call_flow.map((row, tableIndex)=> {

    //                 let tableUtcDate = moment.utc(row.starttime, "YYYY-MM-DD HH:mm:ss A").toDate();
    //                 let tableTimeStamp = moment(tableUtcDate, "YYYY-MM-DD HH:mm:ss A").local();
    //                 const tableStartTime = tableTimeStamp.format('hh:mm:ss');

    //                 let talkTimeUtc = moment.utc(row.talktime, "YYYY-MM-DD HH:mm:ss A").toDate();
    //                 let talkTimeLocal = moment(talkTimeUtc, "YYYY-MM-DD HH:mm:ss A").local();
    //                 let talkTime = talkTimeLocal.format('hh:mm:ss');
    //                 if(talkTime === "Invalid date") {
    //                     talkTime = ""
    //                 }
    //                 let answer = row.answer;
    //                 if(answer ===  `""`){
    //                     answer = "";
    //                 }
    //                 let target = row.target;
    //                 if(target ===  `""`){
    //                     target = "";
    //                 }

    //                 if(answer === "ExternalTwin" || row.initiation === "Held"){
    //                     return null
    //                 }
    
    //                 return(
    //                     <tr>
    //                         {/* <td>{tableStartTime}</td> */}
    //                         {/* <td>{row.initiation}</td> */}
    //                         <td>{row.callingnumber}</td>
    //                         <td>{row.dialednumber}</td>
    //                         <td>{target}</td>
    //                         {/* <td>{talkTime}</td> */}
    //                         <td>{row.talktime_duration}</td>
    //                         <td>{row.initiation}</td>
    //                     </tr>
    //                 )
    
    //             });
    //         }
            

    //         return (
    //             <Auxi>
    //                 {calendarValue}
                    
    //                 <div className="call-logs-container">
    //                     {iconDiv}
    //                     <div style={record.name === ""?{color:"white"}:null}className="call-name"
    //                         data-id={"tableRow"+index} onClick={this.toggleCallFlows}>
    //                         {record.name === ""? "#": record.name}
    //                     </div>
    //                     <div className="call-number" 
    //                         data-id={"tableRow"+index} onClick={this.toggleCallFlows}>
    //                         {record.destination}
    //                     </div>
    //                     <div className="call-date-duration"
    //                         data-id={"tableRow"+index} onClick={this.toggleCallFlows}>
    //                         {datePart} &nbsp; {timePart} &nbsp; {duration}
    //                     </div>
    //                         {direction}
    //                 </div>
    //                 <table id={"tableRow"+index} className="cdr-table">
    //                     <tr>
    //                         <th>Originator</th>
    //                         <th>Dialed</th>
    //                         <th>Target</th>
    //                         <th>Talk Time</th>
    //                         <th>Action</th>
    //                     </tr>
    //                     {tableData}
    //                 </table>
    //             </Auxi>
    //         )
    //     });
    //     this.setState({recordsUi: recordsUi})
    // }

    // toggleCallFlows = (evt) => {
    //     const id = evt.target.getAttribute("data-id");
    //     const elem = document.getElementById(id)
    //     if(window.getComputedStyle(elem).display === "none"){
    //         // hidden
    //         elem.style.display = "table";
    //     } else {
    //         //visible
    //         elem.style.display = "none";
    //     }
    // }
    toggleCallFlows = (evt, record) => {
        this.props.history.push({
            pathname: '/cdrs-table',
            state: { record }, // Pass the record as props
        });
    };

    searchChangeHandler = (evt) => {
        const value = evt.target.value;
        this.setState({searchValue: value});
        const lowerCaseValue = value.toLowerCase();

        const filteredResults = this.state.filteredResults.filter((record) => {

            if((record.name.toLowerCase()).includes(lowerCaseValue) ||
                (record.destination.toLowerCase()).includes(lowerCaseValue)){
                return record;
            }
            return null
        });

        this.populateRecords(filteredResults);
    }

    // filterChangeHandler = (filterName) => {
    //     this.setState({filter: filterName});
    //     this.setState({searchValue: ""});

    //     if(filterName === "all"){
    //         this.populateRecords(this.state.cdrRecords);
    //         this.setState({filteredResults: this.state.cdrRecords});
    //         return;
    //     }

    //     const filteredResults = this.state.cdrRecords.filter((record) => {
    //         if(filterName === "Missed"){
    //             if(record.direction === filterName || record.direction === "Missed-VM"){
    //                 return record;
    //             } 
    //         }
    //         else if(record.direction === filterName){
    //             return record;
    //         }
    //     });

    //     this.setState({filteredResults: filteredResults});

    //     this.populateRecords(filteredResults);
    // }

    filterChangeHandler = (filterName) => {
        this.setState({ filter: filterName });
        this.setState({ searchValue: "" });
    
        if (filterName === "all") {
            // Show all records
            this.populateRecords(this.state.cdrRecords);
            this.setState({ filteredResults: this.state.cdrRecords });
            return;
        }
    
        const filteredResults = this.state.cdrRecords?.filter((record) => {
            // Filter based on the selected direction
            if (filterName === "Missed") {
                return record.direction === "Missed" || record.direction === "Missed-VM";
            }
            return record.direction === filterName;
        });
    
        this.setState({ filteredResults });
        this.populateRecords(filteredResults);
    };

    typeChangeHandler = (type) => {
        this.setState({ type });
        this.getCdrs(type, this.state.userInfo.extension, this.state.userInfo.tenant_id, this.state.token);
    }
    
    render() {

        return(
            <div>
                
                <div className = "cdrs">
                    <div className="row">
                        <div className="col-xs-0 col-md-2"> </div>
                        <div className = "col-xs-12 col-md-8">
                            <div className="header">
                                <div className="row search">
                                    <div className = "col-md-12 search-container"> 
                                        <input type="text"  className="form-control"
                                            placeholder="Search here..."
                                            onChange={this.searchChangeHandler} 
                                            value={this.state.searchValue}
                                        />
                                        <span> <FontAwesomeIcon icon={faSearch} /></span>
                                    </div>
                                </div>

                                <div className="row filter-container">
                                    <div className = {this.state.filter === "all"?"col-3 col-md-3 filter active-filter":"col-3 col-md-3 filter"}
                                        onClick={e => this.filterChangeHandler("all")}> 
                                        <label>All</label>
                                    </div>
                                    <div className = {this.state.filter === "Missed"?"col-3 col-md-3 filter active-filter":"col-3 col-md-3 filter"}
                                        onClick={e => this.filterChangeHandler("Missed")}> 
                                        <label>Missed</label>
                                    </div>
                                    <div className = {this.state.filter === "Inbound"?"col-3 col-md-3 filter active-filter":"col-3 col-md-3 filter"}
                                        onClick={e => this.filterChangeHandler("Inbound")}> 
                                        <label>Inbound</label>
                                    </div>
                                    <div className = {this.state.filter === "Outbound"?"col-3 col-md-3 filter active-filter":"col-3 col-md-3 filter"}
                                        onClick={e => this.filterChangeHandler("Outbound")}> 
                                        <label>Outbound</label>
                                    </div>
                                </div>
                                <div className="m-2">
                                    <select className="form-control" 
                                        onChange={e => this.typeChangeHandler(e.target.value)} 
                                        defaultValue='User'>
                                            <option value='User'>My Calls</option>
                                            <option value='Group'>Group Calls</option>

                                        </select>
                                </div>
                            </div>

                                <div className="body"  onScroll={this.handleScroll}>
                                    
                                <PullToRefresh
                                    onRefresh={e => this.getCdrs(this.state.type, this.state.userInfo.extension, this.state.userInfo.tenant_id, this.state.token)}
                                >
                                    {this.state.recordsUi}
  
                                </PullToRefresh>
                                </div> 

                        </div>
                        <div className="col-xs-0 col-md-2"></div>
                    </div>
                </div>
                <ToastContainer />
            </div>

        );

    }

}

export default CDRS;
