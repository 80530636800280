import React, {useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import './CDRsTable.css'; // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPhoneAlt,faUser } from '@fortawesome/free-solid-svg-icons'; // Import icons
import ReactAudioPlayer from 'react-audio-player'; // Import ReactAudioPlayer
import moment from 'moment'; 

const CDRsTable = (props) => {
    const record = props.location.state?.record;
    useEffect(() => {
        console.log(record);
    }, []);
    if (!record) {
        return <div className="no-data">No data to display.</div>;
    }
    

    // Generate direction-based icon logic
    let directionIcon = <a href={`sip:${record.destination}?dialAction=autoCall`} className="call-status inbound-hover">
        <FontAwesomeIcon
            icon={faPhoneAlt}
            className="back-icon"

        />
    </a>

    const tableData = record.ans_call_flow.map((row, index) => (
        <tr key={index}>
            <td>{row.callingnumber}</td>
            <td>{row.dialednumber}</td>
            <td>{row.target || 'N/A'}</td>
            <td>{row.talktime_duration || 'N/A'}</td>
            <td>{row.initiation || 'N/A'}</td>
        </tr>
    ));

    let utcDate = moment.utc(record.time_stamp, "YYYY-MM-DD HH:mm:ss A").toDate();
    let timeStamp = moment(utcDate, "YYYY-MM-DD HH:mm:ss A").local();

    const datePart = timeStamp.format("YYYY-MM-DD");
    const timePart = timeStamp.format("h:mm:ss A");

    return (
        <div className="cdr-table-container">
            {/* Header with Back Icon */}
            <div className="header-container">
                <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="back-icon"
                    onClick={() => props.history.goBack()}
                />
                <h3 className="header-title">Calls</h3>
            </div>

            {/* Call Summary Section */}
            <h1 style={{fontSize:20, width: "100%", textAlign: "center", fontWeight:"700"}} 
            className={record.direction === "Inbound" ? "inbound-call" : record.direction === "Outbound" ? "outbound-call" : "missed-call"}>
                {record.direction}
            </h1>
            <div className="call-summary">
                {
                    record.name ?
                        <div className="call-summary-icon">
                            <span>
                                {record.name.split(' ').map((n) => n[0]).join('')}
                            </span>
                        </div>
                    :
                    <div className="call-user-icon">
                        <span>
                            <FontAwesomeIcon icon={faUser} />
                        </span>
                    </div>
                }
                <div className="call-summary-details">

                    <p className="call-summary-name">{record.name || '#'}</p>
                    <p className="call-summary-extension">{record.destination}</p>
                    <p className="call-summary-timestamp">
                        {datePart} &nbsp; {timePart}
                    </p>
                </div>
                <div className="call-summary-action">{directionIcon}</div>
            </div>

            {/* Call Recording */}
            {record.recording_url && (
                <div className="call-recording">
                    <ReactAudioPlayer
                        src={record.recording_url}
                        controls
                        style={{ width: '100%' }}
                    />
                </div>
            )}

            {/* Call Flow Table */}
            <table className="cdr-table">
                <thead>
                    <tr>
                        <th>Originator</th>
                        <th>Dialed</th>
                        <th>Target</th>
                        <th>Talk Time</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>{tableData}</tbody>
            </table>
        </div>
    );
};

export default withRouter(CDRsTable);